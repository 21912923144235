<template>
  <div id="projects_layout">
    <section class="">
      <div id="projects_table">
        <div id="projects_tabletop" class="row wide">
          <div class="padding-xy-4 padding-x-0-desktop padding-y-5-tablet">
            <div class="columns is-multiline is-mobile is-vcentered">
              <div class="column">
                <b-field>
                  <b-taginput
                    v-model="searchTerms"
                    placeholder="Search..."
                    icon="search"
                    type="is-dark"
                    class="is-capitalized"
                    rounded
                    @remove="removeSearchTerm"
                    @input="updateSearchTermsCache" />
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-dropdown :mobile-modal="false" position="is-bottom-left" style="width: 100%">
                  <button slot="trigger" class="button is-primary">
                    <span>Project</span>
                    <b-icon icon="plus-circle" size="is-small" />
                  </button>
                  <b-dropdown-item v-if="canManufacturing" has-link>
                    <router-link :to="{ name: 'royalty_tracker_project', params: { type: 'manufacturing', id: 'new' } }">
                      Manufacturing
                    </router-link>
                    <!-- <a @click="goNewProject('manufacturing')">
                      Manufacturing
                    </a> -->
                  </b-dropdown-item>
                  <b-dropdown-item v-if="canRepair" has-link>
                    <!-- <router-link :to="{ name: 'royalty_tracker_project', params: { type: 'repair', id: 'new' }}">
                      Repair
                    </router-link> -->
                    <a @click="goNewProject('repair')">
                      Repair
                    </a>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="canAccessory" has-link>
                    <!-- <router-link :to="{ name: 'royalty_tracker_project', params: { type: 'accessory', id: 'new' } }">
                      Accessory
                    </router-link> -->
                    <a @click="goNewProject('accessory')">
                      Accessory
                    </a>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>

        <div v-if="(profile.type == 'jfe') && (profile.hasJfeAdminPermission)">
          <div class="project_group">
            <div class="columns is-mobile is-multiline is-vcentered">
              <div class="column is-narrow">
                <b-field expanded>
                  <b-field style="min-width: 320px; margin-bottom: 0">
                    <b-autocomplete
                      v-model="selectedLicenseeName"
                      class="field no-icon wrap-item hide-clear"
                      open-on-focus
                      placeholder="Licensee"
                      field="name"
                      :data="licenseesFiltered"
                      @select="onChangeLicensee" />
                  </b-field>
                  <p class="control">
                    <b-select v-if="selectedLicenseeId && licensees.length > 0" v-model="selectedLicenseeDivisionId" @input="updateLicenseeDivisionIdCache">
                      <option :value="null">
                        All Divisions
                      </option>
                      <option v-for="division in licenseesById[selectedLicenseeId].divisions" :key="division.id" :value="division.id">
                        {{ division.name }}
                      </option>
                    </b-select>
                  </p>
                  <p class="control">
                    <b-button v-if="selectedLicenseeId" icon-left="times" class="icon-only is-primary" @click="resetLicensee()" />
                  </p>
                </b-field>
              </div>

              <!-- <div v-if="selectedLicenseeId" class="column is-narrow">
                <b-field expanded>
                  <p class="control">
                    <b-select v-model="selectedConnectionId" expanded name="connection" placeholder="Connection" @input="updateConnectionFilter">
                      <option v-for="connection in connectionsFiltered" :key="connection.id" :value="connection.id">
                        {{ connection.name }}
                      </option>
                    </b-select>
                  </p>
                  <p class="control">
                    <b-button icon-left="times" class="icon-only is-primary" @click="resetConnection()" />
                  </p>
                </b-field>
              </div>

              <div v-if="selectedConnectionId" class="column is-narrow">
                <b-field expanded>
                  <p class="control">
                    <b-select v-model="selectedSizeId" expanded name="connection" placeholder="Size" @input="updateSizeFilter">
                      <option v-for="size in sizesFiltered" :key="size.id" :value="size.id">
                        {{ size.size }}
                      </option>
                    </b-select>
                  </p>
                  <p class="control">
                    <b-button icon-left="times" class="icon-only is-primary" @click="resetSize()" />
                  </p>
                </b-field>
              </div> -->

              <div class="column">
                <b-tooltip v-if="selectedLicenseeId == null" label="To invoice projects, please select a licensee.">
                  <b-switch disabled>
                    Invoice Mode
                  </b-switch>
                </b-tooltip>
                <b-switch v-else v-model="selectedInvoiceProject" true-value="1" false-value="0" @input="updateInvoiceProject">
                  Invoice Mode
                </b-switch>
              </div>

              <div v-if="selectedLicenseeId != null" class="column is-narrow">
                <div class="columns is-mobile is-multiline is-vcentered is-gapless">
                  <div v-if="searchTerms.includes('invoice = invoiced')" class="column is-narrow">
                    <b-field style="min-width: 250px; margin-bottom: 0">
                      <autocomplete-single
                        v-model="selectedInvoice"
                        class="field no-icon wrap-item hide-clear"
                        placeholder="Invoices"
                        icon="file-check"
                        :open-on-focus="true"
                        :items="invoicesFiltered"
                        text-field="invoiceLabel1"
                        value-field="invoiceId" />
                    </b-field>
                  </div>
                  <div v-if="selectedInvoiceProject == '1' && projectsFiltered.length > 0" class="column is-narrow">
                    <b-button type="is-success  has-text-weight-bold" style="margin-left: 10px" @click="createInvoice">
                      Create Invoice
                    </b-button>
                  </div>
                  <div v-if="searchTerms.includes('invoice = invoiced')" class="column is-narrow">
                    <b-button v-if="selectedInvoice" type="is-white is-outlined" style="margin-left: 10px" @click="goToInvoice">
                      View Invoice
                    </b-button>
                  </div>
                </div>
              </div>
              <!-- <div v-if="(node_env === 'development') && (profile.username === 'vini@jw.solar' || profile.username === 'curtis@jw.solar')" class="column is-narrow">
                <b-field v-if="showDummyButton" style="min-width: 370px; margin-bottom: 0">
                  <b-input v-model="numberOfDummies" name="xunxo" />
                  <b-button class="icon-only is-primary" @click="create5000Dummies()">
                    Create {{ numberOfDummies }} Dummy Projects
                  </b-button>
                </b-field>
                <b-field v-else>
                  Creating project #{{ countDummiesProjects }} of {{ numberOfDummies }}
                </b-field>
              </div> -->
            </div>
          </div>
        </div>
        <b-table
          class="scrollable clickable-row"
          :data="projectsFiltered"
          :mobile-cards="false"
          :hoverable="true"
          :checkable="(selectedLicenseeId != null && selectedInvoiceProject == '1')"
          :is-row-checkable="(exportMode ? (row) => true : (row) => row.invoice=== 'ready')"
          checkbox-position="left"
          :checked-rows.sync="checkedRows"
          :sort-icon="'arrow-up'"
          :sort-icon-size="'is-small'"
          paginated
          :current-page.sync="page"
          :total="projectsTotal"

          backend-pagination
          :per-page="selectedInvoiceProject === '1' ? 99999 : limitPerPage"
          :loading="loadingProjects"

          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"

          @click="goToProject"
          @page-change="onPageChange">

          <b-table-column width="10" field="type">
            <template v-slot:header="{ column }">
              <span style="margin-right: 3px;">{{ column.label }}</span>
              <b-dropdown :mobile-modal="false">
                <b-button slot="trigger" icon-right="filter" size="is-small" :class="typeFilterProjects.find(sfp => sfp.items.find(item => hasSearchTerm('type', item.value))) ? 'is-info padding-xy-1' : ' is-transparent is-dark padding-xy-1'" />
                <b-dropdown-item v-for="group in typeFilterProjects" :key="group.value" custom>
                    <div v-for="item in group.items" :key="item.label" class="padding-y-1">
                      <div class="media">
                        <div class="media-content">
                          <b-checkbox :value="hasSearchTerm(item.pathway, item.value)" @input="toggleSearchTerm('type', item.value)">
                            {{ item.label }}
                          </b-checkbox>
                        </div>
                      </div>
                    </div>
                  </b-dropdown-item>
                <b-dropdown-item class="has-text-right">
                  <small><a>close</a></small>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot="props">
              <div class="has-text-right">
                <span class="tag is-rounded project-type" :class="[props.row.type]">{{ props.row.type.substring(0, 1).toUpperCase() }}</span>
              </div>
            </template>
          </b-table-column>
          <b-table-column width="10" field="invoiceStatus" label="Status" class="status_column" centered>
            <template v-slot:header="{ column }">
              <span style="margin-right: 3px;">{{ column.label }}</span>
              <div v-if="selectedInvoiceProject !== '1'">
                <b-dropdown :mobile-modal="false" class="has-text-left">
                  <b-button slot="trigger" icon-right="filter" size="is-small" :class="statusFilterProjects.find(sfp => sfp.items.find(item => hasSearchTerm(item.pathway, item.value))) ? 'is-info padding-xy-1' : ' is-transparent is-dark padding-xy-1'" />
                  <b-dropdown-item v-for="group in statusFilterProjects" :key="group.value" custom>
                    <div v-for="item in group.items" :key="item.label" class="padding-y-1">
                      <div class="media">
                        <div class="media-content">
                          <b-checkbox :value="hasSearchTerm(item.pathway, item.value)" @input="toggleSearchTerm(item.pathway, item.value)">
                            {{ item.label }}
                          </b-checkbox>
                        </div>
                        <b-icon :icon="item.icon" :class="item.class" size="is-small" />
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item class="has-text-right">
                    <small><a>close</a></small>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template v-slot="props">
              <span v-if="props.row.status === 'rejected' || props.row.status === 'canceled'">
                <span v-if="props.row.status === 'rejected'" class="has-text-danger">
                  <b-icon icon="ban" size="is-small" />
                <!-- <div><small>Rejected</small></div> -->
                </span>
                <span v-if="props.row.status === 'canceled'" class="has-text-danger">
                  <div><b-icon icon="backspace" size="is-small" /></div>
                  <div>
                    <small>Canceled</small>
                  </div>
                </span>
              </span>
              <span v-else>
                <!-- <span v-if="props.row.invoice === 'unapproved'" class="has-text-danger">
                    <b-icon icon="hourglass-half" size="is-small" />
                    <div><small>In Cycle</small></div>
                  </span> -->
                <span v-if="props.row.invoice === 'pending' || props.row.invoice === 'in cycle' || props.row.invoice === 'unapproved'">
                  <b-icon icon="hourglass-half" class="has-text-grey-light" size="is-small" />
                  <div>
                    <small>In Cycle</small>
                  </div>
                </span>
                <span v-if="props.row.invoice === 'ready'">
                  <span><b-icon icon="file-minus" class="has-text-primary" size="is-small" /></span>
                  <div>
                    <small>Ready</small>
                  </div>
                </span>
                <span v-if="props.row.invoice === 'unsent'">
                  <div><b-icon icon="file" class="has-text-success" size="is-small" /></div>
                  <div>
                    <small>Invoiced</small>
                  </div>
                </span>
                <span v-if="props.row.invoice === 'sent'">
                  <div><b-icon icon="file-check" class="has-text-success" size="is-small" /></div>
                  <div>
                    <small>Invoice Sent</small>
                  </div>
                </span>
                <span v-if="props.row.invoice === 'paid'">
                  <div><b-icon icon="dollar-sign" class="has-text-success" size="is-small" /></div>
                  <div>
                    <small>Paid</small>
                  </div>
                </span>
                <span v-if="props.row.invoice === 'due'">
                  <div><b-icon icon="dollar-sign" class="has-text-danger" size="is-small" /></div>
                  <div>
                    <small>Overdue</small>
                  </div>
                </span>
              </span>
            </template>
          </b-table-column>
          <b-table-column v-slot="props" field="orderNumber" label="Order Number">
            <div v-if="props.row.status === 'accepted'">
              {{ props.row.orderNumber }}
            </div>
            <div v-else-if="props.row.status === 'rejected'" class="has-text-danger">
              Rejected
            </div>
            <div v-else>
              <b-button v-if="props.row.status !== 'rejected' && props.row.status !== 'canceled'" type="is-primary" size="is-small">
                Requested
              </b-button>
            </div>
          </b-table-column>
          <b-table-column v-slot="props" :visible="(profile.type !== 'jfe')" label="PO #">
            <div>
              {{ props.row.internalId }}
            </div>
          </b-table-column>
          <b-table-column :visible="(!selectedLicenseeId && profile.type == 'jfe') && (profile.hasJfeAdminPermission) ? true : false" field="office" label="Office">
            <template v-slot:header="{ column }">
              <span style="margin-right: 3px;">{{ column.label }}</span>

              <div v-if="selectedInvoiceProject !== '1'">
                <b-dropdown :mobile-modal="false" class="has-text-left">
                  <b-button slot="trigger" icon-right="filter" size="is-small" :class="selectedOffices.length > 0 ? 'is-info padding-xy-1' : ' is-transparent is-dark padding-xy-1'" />
                  <b-dropdown-item custom>
                    <div class="padding-y-1">
                      <div class="media">
                        <div class="media-content">
                          <b-button @click.native="allOffices()">
                            All Offices
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item custom>
                    <div v-for="office in offices" :key="office.id" class="padding-y-1">
                      <div class="media">
                        <div class="media-content">
                          <b-checkbox :value="hasOffice(office.id)" @input="toggleOffice(office.id)">
                            {{ office.name }}
                          </b-checkbox>
                        </div>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item class="has-text-right">
                    <small><a>close</a></small>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template v-slot="props">
              {{ props.row.officeName }}
            </template>
          </b-table-column>
          <b-table-column v-slot="props" :visible="(!selectedLicenseeId && profile.type == 'jfe') && (profile.hasJfeAdminPermission) ? true : false" field="licensee" label="Licensee">
            {{ props.row.licenseeName }}
          </b-table-column>
          <b-table-column v-slot="props" field="division" label="Division">
            {{ props.row.licenseeDivisionName }}
          </b-table-column>
          <b-table-column v-slot="props" field="projectName" label="Project">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column v-slot="props" field="endUser" label="End User">
            {{ props.row.endUser }}
          </b-table-column>
          <b-table-column field="date" label="Completion Date">
            <template v-slot:header="{ column }">
              <span style="margin-right: 3px;">{{ column.label }}</span>
              <section>
                <b-datepicker v-model="selectedRangeDate" :mobile-native="false" :close-on-click="true" range ref="datepickerRange" @input="updateRangeDateFilter">
                  <template v-slot:trigger>
                    <b-button slot="trigger" icon-right="filter" size="is-small" :class="selectedRangeDate ? 'is-info padding-xy-1' : ' is-transparent is-dark padding-xy-1'" />
                  </template>
                    <b-button
                      label="Clear"
                      type="is-danger"
                      class="has-text-left"
                      outlined
                      @click="resetCompletion" />
                    <b-button
                      label="Close"
                      type="is-info"
                      class="is-pulled-right"
                      @click="$refs.datepickerRange.toggle()"
                      outlined />
                </b-datepicker>
              </section>
            </template>
            <template v-slot="props">
              {{ props.row.endDate | moment("M/D/YY") }}
            </template>
          </b-table-column>
          <b-table-column width="10" field="connection" label="Connection" class="status_column" centered>
            <template v-slot:header="{ column }">
              <span style="margin-right: 3px;">{{ column.label }}</span>
              <div v-if="selectedLicenseeId && licenseeConnections.length > 0">
                <b-dropdown v-model="selectedConnectionId" :mobile-modal="false" class="has-text-left" @input="updateConnectionFilter">
                  <b-button slot="trigger" icon-right="filter" size="is-small" :class="selectedConnectionId ? 'is-info padding-xy-1' : ' is-transparent is-dark padding-xy-1'" />
                  <b-dropdown-item v-for="connection in connectionsFiltered" :key="connection.id" :value="connection.id" @click="!hasSearchTerm('connection', connection.name) ? toggleSearchTerm('connection', connection.name) : false">
                    {{ connection.name }}
                  </b-dropdown-item>
                  <b-dropdown-item class="has-text-right">
                    <small><a>close</a></small>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template v-slot="props">
            {{ props.row.connectionName }}
            </template>
          </b-table-column>
          <b-table-column width="10" field="size" label="Size">
            <template v-slot:header="{ column }">
              <span style="margin-right: 3px;">{{ column.label }}</span>
              <div v-if="hasConnectionFilter">
                <b-dropdown v-model="selectedSizeId" :mobile-modal="false" class="has-text-left" @input="updateSizeFilter">
                  <b-button slot="trigger" icon-right="filter" size="is-small" :class="selectedSizeId ? 'is-info padding-xy-1' : ' is-transparent is-dark padding-xy-1'" />
                  <b-dropdown-item v-for="size in sizesFiltered" :key="size.id" :value="size.id" @click="!hasSearchTerm('size', size.size) ? toggleSearchTerm('size', size.size) : false">
                    {{ size.size }}"
                  </b-dropdown-item>
                  <b-dropdown-item class="has-text-right">
                    <small><a>close</a></small>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template v-slot="props">
              <div v-if="props.row.hasWrongConnectionId === 1" class="is-danger" style="background-color: red; color:white">
                <b-tooltip type="is-dark" multilined size="is-large" position="is-top">
                  <span>Not set</span>
                  <template v-slot:content>
                    <span v-html="'Please review this project and make sure it is consistent before sending the invoice'" />
                  </template>
                </b-tooltip>
              </div>
              <div v-else>{{ props.row.connectionSize }}"</div>
            </template>
          </b-table-column>
          <b-table-column v-slot="props" field="connectionWeight" label="Weight" sortable numeric centered>
            {{ props.row.connectionWeight }}#
          </b-table-column>
          <b-table-column width="10" field="size" label="Grade">
            <template v-slot:header="{ column }">
                <span style="margin-right: 3px;">{{ column.label }}</span>
                <b-dropdown v-model="selectedGradeId" :mobile-modal="false" class="has-text-left" append-to-body aria-role="menu" scrollable max-height="200" trap-focus @input="updateGradeFilter">
                    <b-button slot="trigger" icon-right="filter" size="is-small" :class="selectedGradeId ? 'is-info padding-xy-1' : ' is-transparent is-dark padding-xy-1'" />
                    <b-dropdown-item custom aria-role="listitem">
                      <b-input v-model="gradeSearchTerm" placeholder="search" expanded />
                    </b-dropdown-item>
                    <b-dropdown-item v-for="grade of gradesFiltered.filter(item => item.name.toLowerCase().indexOf(gradeSearchTerm.toLowerCase()) >= 0)" :key="grade.id" :value="grade.id" aria-role="listitem" @click="!hasSearchTerm('grade', gradeById[grade.id].name) ? toggleSearchTerm('grade', gradeById[grade.id].name) : false">{{grade.name}}</b-dropdown-item>
                    <b-dropdown-item class="has-text-right">
                      <small><a>close</a></small>
                    </b-dropdown-item>
                </b-dropdown>
            </template>
            <template v-slot="props">
              <span v-if="props.row.materialType===1">{{ props.row.connectionGradeName }}</span>
              <span v-if="props.row.materialType===2">{{ props.row.craDescription }}</span>
              <span v-if="props.row.materialType===3">Other Material</span>
            </template>
          </b-table-column>
          <b-table-column v-slot="props" field="totalUnits" label="Units">
            <span v-if="(props.row.type === 'manufacturing')">
              <div>{{ (props.row.joints || 0) + (props.row.couplings || 0) + ' units' || '-' }}</div>
              <small v-if="props.row.joints">{{ props.row.joints + ' j' }}</small>
              <small v-if="props.row.joints && props.row.couplings"> + </small>
              <small v-if="props.row.couplings">{{ props.row.couplings + ' c' }}</small>
            </span>
            <span v-else>
              <div>{{ (props.row.pins || 0) + (props.row.boxes || 0) + ' units' || '-' }}</div>
              <small v-if="props.row.pins">{{ props.row.pins + ' p' }}</small>
              <small v-if="props.row.pins && props.row.boxes"> + </small>
              <small v-if="props.row.boxes">{{ props.row.boxes + ' b' }}</small>
            </span>
          </b-table-column>
          <b-table-column v-slot="props" field="tonnage" label="Tonnage">
            <span v-if="(props.row.type === 'manufacturing')">
              <div v-if="props.row.joints && props.row.jointLength && props.row.connectionWeight">{{ numberFormat((((props.row.joints || 0) * (props.row.jointLength || 0) * (props.row.connectionWeight || 0)) / 2205), 2) || '' }}</div>
              <div v-else></div>
            </span>
            <span v-else>
              <div></div>
            </span>
          </b-table-column>

          <b-table-column v-slot="props" field="royalty" label="Royalty" width="40" numeric>
            <template v-if="props.row.connectionRate">
              {{ props.row.connectionRate | currency }}
            </template>
            <template v-else>
              --
            </template>
            <small v-if="props.row.connectionNewRate && props.row.connectionNewRateStatus === 1"><br><span class="has-text-orange">{{ props.row.connectionNewRate | currency }}</span></small>
          </b-table-column>
          <b-table-column v-slot="props" field="total" label="Total" width="40" numeric>
            <template v-if="props.row.royalty">
              {{ props.row.royalty | currency }}
            </template>
            <template v-else>
              --
            </template>
            <small v-if="props.row.connectionNewRate && props.row.connectionNewRateStatus === 1"><br><span class="has-text-orange">{{ newRoyalty(props.row) | currency }}</span></small>
          </b-table-column>

          <template slot="footer">
            <b-switch v-model="exportMode" type="is-success">
              Export
            </b-switch>
            <b-button v-if="exportMode" type="is-white is-rounded " style="margin-left: 15px" icon-left="download" @click="generateCsv">
              CSV
            </b-button>
          </template>
          <template slot="empty">
            <div class="padding-xy-6 has-text-centered">
              No projects
            </div>
          </template>
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>

import Vue2Filters from 'vue2-filters'
import Moment from 'moment'
import PapaParse from 'papaparse'
import RandomString from 'randomstring'

import AdminApi from '@/apis/AdminApi'
import ReportsApi from '@/apis/ReportsApi'
import ProjectsApi from '@/apis/ProjectsApi'
import InvoicesApi from '@/apis/InvoicesApi'

import ProjectsUtil from '@/utils/ProjectsUtil'

export default {
  name: 'Projects',
  components: {},
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      // dataProjects: [],
      node_env: process.env.NODE_ENV,
      selectedLicenseeId: null,
      selectedLicenseeDivisionId: null,
      selectedOffices: [],
      selectedProjects: null,
      selectedInvoice: null,
      selectedInvoiceProject: 0,
      selectedPeriod: null,
      selectedConnectionId: null,
      selectedRangeDate: null,
      selectedSizeId: null,
      selectedGradeId: null,
      showGradeFilter: false,
      searchTerms: [],
      exportMode: false,
      loaded: false,
      gradeSearchTerm: '',
      loadingProjects: true,
      checkedRows: [],
      invoice: null,
      radio: 'all',
      numberOfDummies: 5000,
      showDummyButton: true,
      countDummiesProjects: 1,
      userLicenseeConnections: [],
      selectedLicenseeName: null,
      selectedLicensee: null,
      licenseeConnections: [],
      projectsTotal: 0,
      page: 1,
      limitPerPage: 50,
      defaultSortOrder: 'DESC',
      sortField: 'id',
      sortOrder: 'DESC',
      userCached: {
        cachedSearchTerms: null,
        cachedSelectedOffices: null,
        cachedSelectedInvoiceProject: null,
        cachedSelectedLicenseeId: null,
        cachedSelectedLicenseeName: null,
        cachedSelectedLicenseeDivisionId: null,
        cachedSelectedConnectionId: null,
        cachedSelectedRangeDate: null,
        cachedSelectedSizeId: null,
        cachedSelectedGradeId: null,
        cachedCurrentPage: 1
      },
      sizesFiltered: [],
      statusFilterProjects: [
        {
          label: '-----',
          items: [
            { label: 'Requested', pathway: 'status', value: 'requested', icon: 'exclamation-circle', class: 'has-text-orange' }
          ],
          icon: null
        },
        {
          label: 'Projects',
          items: [
            { label: 'In Cycle', pathway: 'invoice', value: 'in cycle', icon: 'hourglass-half', class: 'has-text-grey-light' },
            { label: 'Ready to Invoice', pathway: 'invoice', value: 'ready', icon: 'file-minus', class: 'has-text-primary' },
            { label: 'Invoiced', pathway: 'invoice', value: 'invoiced', icon: 'file-check', class: 'has-text-success' },
            { label: 'Paid', pathway: 'invoice', value: 'paid', icon: 'dollar-sign', class: 'has-text-success' }
          ],
          icon: null
        },
        {
          label: '-----',
          items: [
            { label: 'Canceled', pathway: 'status', value: 'canceled', icon: 'backspace', class: 'has-text-danger' },
            { label: 'Rejected', pathway: 'status', value: 'rejected', icon: 'ban', class: 'has-text-danger' }
          ],
          icon: null
        }
      ],
      typeFilterProjects: [
        {
          label: '-----',
          items: [
            { label: 'Manufacturing', pathway: 'manufacturing', value: 'manufacturing', icon: null, class: 'has-text-orange' },
            { label: 'Repair', pathway: 'repair', value: 'repair', icon: null, class: 'has-text-orange' },
            { label: 'Accessory', pathway: 'accessory', value: 'accessory', icon: null, class: 'has-text-orange' }
          ]
        }
      ]
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    canManufacturing () {
      return this.profile.type === 'jfe' ? true : this.userLicenseeConnections.find(ulc => ulc.manufacture)
    },
    canRepair () {
      return this.profile.type === 'jfe' ? true : this.userLicenseeConnections.find(ulc => ulc.repair)
    },
    canAccessory () {
      return this.profile.type === 'jfe' ? true : this.userLicenseeConnections.find(ulc => ulc.accessory)
    },
    connections () {
      return this.$store.state.connectionsLight
    },
    licensees () {
      let licenseesFiltered = this.$store.state.licensees.filter(l => {
        if (this.selectedOffices.length > 0) {
          return this.selectedOffices.find(id => id === l.officeId)
        } else {
          return true
        }
      })
      return this.orderBy(licenseesFiltered, 'name')
    },
    licenseesFiltered () {
      if (this.selectedLicenseeName) {
        return this.licensees.filter(l => {
          return (
            l.name.toString().toLowerCase().indexOf(this.selectedLicenseeName.toLowerCase()) >= 0
          )
        })
      } else {
        return this.licensees
      }
    },
    // licenseesFiltered () {
    //   return this.licensees.filter(l => this.selectedOffices.some(term => term === l.officeId))
    // },
    offices () {
      return this.orderBy(this.$store.state.offices, 'name')
    },
    selectedString () {
      let options = { year: 'numeric', month: 'short', day: 'numeric' }
      return this.selectedRangeDate ? this.selectedRangeDate.map(d => d.toLocaleDateString(undefined, options)).join(' - ') : ''
    },
    projects () {
      var projects = this.$store.state.projects
      // Update all Invoice Status
      this.getInvoiceStatus(projects.projects)

      return this.orderBy(projects.projects, 'endDate', -1)
    },
    invoices () {
      return this.$store.state.invoices
    },
    invoicesFiltered () {
      return this.invoices.filter(invoice => {
        return this.selectedLicenseeId === invoice.licenseeId
      })
    },
    connectionByName () {
      const mapped = {}

      this.connections.forEach(connection => {
        mapped[connection.name] = connection
      })

      return mapped
    },
    gradeById () {
      const mapped = {}

      this.gradesFiltered.forEach(grade => {
        mapped[grade.id] = grade
      })

      return mapped
    },
    projectsFiltered () {
      /*
       * Don't forget, this filter works like this:
       * First filter is true, do the next step. False, exclude it from the filter.
      */
      return this.projects
        .filter(project => {
          if (this.selectedProjects) {
            if (typeof this.selectedProjects === 'object') { // Test if is the period
              let date = Moment(project.endDate)
              return (!this.selectedProjects || date.isBetween(this.selectedProjects.start, this.selectedProjects.end, null, '[]')) && ((project.invoice === 'unsent' || project.invoice === 'sent'))
            } else {
              // For those records are not updated yet (old records)
              if ((this.selectedProjects === 'requested') && (project.type === 'manufacturing') && (project.status == null)) {
                return true
              }

              switch (this.selectedProjects) {
                case 'ready':
                  return this.selectedProjects === project.invoice
                case 'invoiced':
                  if ((project.invoice === 'unsent') || (project.invoice === 'sent')) {
                    return true
                  }
                  break
                default:
                  return this.selectedProjects === project.status
              }
            }
          } else {
            return true
          }
        })
        .filter(project => {
          // Don't show the projects canceled and rejected on the first main list
          if ((this.searchTerms.length === 0) && ((project.status === 'canceled'))) {
            return false
          } else {
            return true
          }
        })
    },
    licenseesById () {
      let mapped = {}

      this.licensees.forEach(licensee => {
        mapped[licensee.id] = licensee
      })

      return mapped
    },
    periods () {
      const periods = []
      let now = Moment()
      let date = Moment(now.format('YYYY') + '-01-01')

      if (this.selectedLicenseeId) {
        let licensee = this.licenseesById[this.selectedLicenseeId]
        let periodMonths = 1

        if (licensee.billingCycle === 'Quarterly') {
          periodMonths = 3
        } else if (licensee.billingCycle === 'Semiannually') {
          periodMonths = 6
        }

        while (date.isBefore(now)) {
          let periodStart = date.clone()
          let periodEnd = periodStart.clone().add(periodMonths, 'month').subtract(1, 'day')
          var labelFormat = 'MMM, YYYY' // Monthly

          if (licensee.billingCycle === 'Quarterly') {
            labelFormat = 'YYYY - [' + ProjectsUtil.getQuarter('Text', periodEnd.toDate()) + ']'
          } else if (licensee.billingCycle === 'Semiannually') {
            if (periodEnd.toDate().getMonth() < 6) {
              labelFormat = 'YYYY - [S1]'
            } else {
              labelFormat = 'YYYY - [S2]'
            }
          }

          let label = Moment(periodStart).format(labelFormat)
          var hasProjectPeriod = this.projects.find(p => (p.invoiceId !== null) && (p.licenseeId === this.selectedLicenseeId) && (Moment(p.endDate).isBetween(periodStart, periodEnd, null, '[]')))

          if (hasProjectPeriod) {
            periods.push({ start: periodStart, end: periodEnd, label: label })
          }

          date = periodEnd.clone().add(1, 'day')
        }
      }

      return periods
    },
    now () {
      return Moment()
    },
    statusFilter: {
      get () {
        return ['requested', 'not_requested'].find(status => this.searchTerms.some(term => term === 'status = ' + status)) || 'all'
      },
      set (val) {
        this.searchTerms = this.searchTerms.filter(term => !term.startsWith('status = '))

        if (val !== 'all') {
          this.searchTerms.push('status = ' + val)
        }
      }
    },
    hasConnectionFilter () {
      return !!['connection'].find(status => this.searchTerms.some(term => term.includes(status))) || null
    },
    connectionsFiltered () {
      return this.connections.filter(connection => connection.royaltyAvailability).filter(connection => {
        var licenseeConnection = this.licenseeConnections.filter(lc => (lc.licenseeId === this.selectedLicenseeId) && (lc.connectionId === connection.id) && (lc.manufacture || lc.repair || lc.accessory))
        if (licenseeConnection.length > 0) return true
        return false
      })
    },
    gradesFiltered () {
      return JSON.parse(JSON.stringify(this.$store.state.connectionGrades.filter(g => g.id !== 0)))
    }
  },
  watch: {
    'projectsFiltered' (projects) {
      this.checkedRows = []
      if (projects) {
        projects.map(project => {
          if (project.invoice === 'ready' && this.selectedLicenseeId != null) {
            this.checkedRows.push(project)
          }
        })
      }
    },
    'selectedInvoiceProject' () {
      if (this.selectedInvoiceProject === '1') {
        // this.searchTerms = []
        this.selectedProjects = null
        this.selectedInvoice = null
      }
    }
  },
  async mounted () {
    // console.log('env:' + this.node_env)
    await this.$store.commit('setTitle', 'Royalty Tracker')
    this.initializeScreen()

    // resize table
    var nonTableElementsHeight = document.getElementById('navbar').clientHeight + document.getElementById('projects_tabletop').clientHeight + document.getElementById('projects_table').getElementsByClassName('level')[0].clientHeight
    var projectsTable = document.getElementById('projects_table').getElementsByClassName('table-wrapper')[0]
    projectsTable.style.minHeight = (window.innerHeight - nonTableElementsHeight) + 'px'
    projectsTable.style.paddingBottom = (document.getElementById('projects_table').getElementsByClassName('level')[0].clientHeight) + 'px'
  },
  methods: {
    async initializeScreen () {
      console.log('window.localStorage=' + JSON.stringify(window.localStorage))
      // Get any cached values
      this.userCached = JSON.parse(window.localStorage.getItem(this.profile.username)) || {}
      this.selectedLicenseeId = this.userCached.cachedSelectedLicenseeId || null
      this.selectedLicenseeName = this.userCached.cachedSelectedLicenseeName || null
      this.selectedLicenseeDivisionId = this.userCached.cachedSelectedLicenseeDivisionId || null
      this.selectedInvoiceProject = this.userCached.cachedSelectedInvoiceProject || false
      this.selectedConnectionId = this.userCached.cachedSelectedConnectionId || null
      this.selectedRangeDate = this.userCached.cachedSelectedRangeDate ? this.userCached.cachedSelectedRangeDate.map(d => new Date(d)) : null
      this.selectedSizeId = this.userCached.cachedSelectedSizeId || null
      this.selectedGradeId = this.userCached.cachedSelectedGradeId || null
      this.searchTerms = this.userCached.cachedSearchTerms || []
      this.selectedOffices = this.userCached.cachedSelectedOffices || []
      this.page = this.userCached.currentPage || 1

      this.$store.commit('setApp', 'royalty')

      // Don't await... let the screen loads first.
      this.$store.dispatch('refreshConnectionsLight', { hasImages: false, hasSizes: false, hasWeights: false })
      this.$store.dispatch('refreshLicensees')
      // await this.$store.dispatch('refreshInvoices')
      this.$store.dispatch('refreshOffices')
      // await this.$store.dispatch('refreshLicenseeConnections')
      this.$store.dispatch('refreshConnectionGrades')

      if (this.profile.type === 'jfe') {
        if (this.profile.hasJfeAdminPermission) {
        } else {
          if (this.profile.hasJfeRoyaltyTrackerPermission) {
            this.selectedOffices = [this.profile.officeId]
          }
        }
      } else {
        this.selectedLicenseeId = this.profile.licenseeId
        let licensee = await AdminApi.getLicenseeById(this.profile.licenseeId)
        this.selectedLicenseeName = licensee.name || null
        this.userLicenseeConnections = await AdminApi.getLicenseeConnectionsByLicenseeId({ id: this.profile.licenseeId })
      }

      if ((this.selectedInvoiceProject) && (this.profile.type === 'jfe') && (this.profile.hasJfeAdminPermission)) {
        this.updateInvoiceProject(this.selectedInvoiceProject)
      } else {
        await this.loadAsyncData()
      }

      this.invoice = this.initialInvoiceState()
      if (this.selectedConnectionId) {
        this.updateConnectionFilter(this.selectedConnectionId)
      }
      if (this.selectedGradeId) {
        this.updateGradeFilter(this.selectedGradeId)
      }
      this.loadingProjects = false
    },
    async loadAsyncData () {
      this.loadingProjects = true

      let params = {
        filter: {
          licenseeId: this.selectedLicenseeId,
          divisions: this.profile.type === 'jfe' ? this.selectedLicenseeDivisionId ? [this.selectedLicenseeDivisionId] : [] : this.profile.divisions,
          searchTerms: this.selectedInvoiceProject === '1' ? this.searchTerms.concat(['invoice = ready']) : this.searchTerms,
          offices: this.selectedOffices,
          endDateRange: this.selectedRangeDate,
          projectTypes: null,
          limit: this.selectedInvoiceProject === '1' ? 99999 : this.limitPerPage,
          page: this.page,
          orderFields: [this.sortField, this.sortOrder]
        }
      }

      await this.$store.dispatch('refreshProjects', params)

      if (this.selectedLicenseeId) {
        this.licenseeConnections = await AdminApi.getLicenseeConnections({ licenseeId: this.selectedLicenseeId })
      }

      this.projectsTotal = this.$store.state.projects.projectsTotal
      this.loadingProjects = false
    },
    initialInvoiceState () {
      return {
        id: null,
        due: false,
        sent: false,
        paid: false,
        sentDate: null,
        dueDate: null
      }
    },
    async onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      await this.loadAsyncData()
    },
    async onChangeLicensee (licensee) {
      this.selectedLicensee = licensee
      this.selectedLicenseeId = null
      this.selectedLicenseeName = null
      this.userCached.cachedSelectedLicenseeId = null
      this.userCached.cachedSelectedLicenseeName = null
      this.userCached.currentPage = 1
      this.licenseeConnections = []

      if (licensee) {
        this.selectedLicenseeId = licensee.id
        this.userCached.cachedSelectedLicenseeId = licensee.id
        this.userCached.cachedSelectedLicenseeName = this.licenseesById[licensee.id].name
        this.selectedLicenseeName = this.licenseesById[licensee.id].name
        this.licenseeConnections = await AdminApi.getLicenseeConnections({ licenseeId: licensee.id })
      }

      this.saveUserCached()
      this.page = 1
      await this.loadAsyncData()
    },
    async onPageChange (page) {
      this.page = page
      this.userCached.currentPage = page
      await this.loadAsyncData()
      this.saveUserCached()
      this.$scrollTo(page, 500) // page = element
    },
    units (project) {
      if (project.type === 'manufacturing') {
        return (project.joints || 0) + (project.couplings || 0)
      } else {
        return (project.pins || 0) + (project.boxes || 0)
      }
    },
    newRoyalty (project) {
      if (project.connectionNewRate && this.units(project)) {
        return project.connectionNewRate * this.units(project)
      }

      return null
    },
    resetLicensee () {
      this.userCached.cachedSelectedLicenseeId = null
      this.userCached.cachedSelectedLicenseeName = null
      this.userCached.cachedSelectedLicenseeDivisionId = null
      this.userCached.cachedSelectedInvoiceProject = null
      this.userCached.cachedSelectedConnectionId = null
      this.userCached.cachedSelectedSizeId = null
      this.userCached.cachedSelectedGradeId = null
      this.saveUserCached()

      this.selectedLicenseeId = null
      this.selectedLicenseeName = null
      this.selectedLicensee = null
      this.selectedLicenseeDivisionId = null
      this.selectedPeriod = null
      this.selectedProjects = null
      this.selectedInvoice = null
      this.selectedInvoiceProject = false
      this.resetConnection()
      this.onChangeLicensee()
    },
    resetConnection () {
      this.selectedConnectionId = null
      this.userCached.cachedSelectedConnectionId = null
      if (this.searchTerms.some(term => term.includes('connection'))) {
        this.searchTerms = this.searchTerms.filter(term => !term.includes('connection')) // Clean search term
        this.updateSearchTermsCache()
      }
      this.saveUserCached()
      this.resetSize()
    },
    resetSize () {
      this.userCached.cachedSelectedSizeId = null
      this.saveUserCached()
      this.selectedSizeId = null
      if (this.searchTerms.some(term => term.includes('size'))) {
        this.searchTerms = this.searchTerms.filter(term => !term.includes('size')) // Clean search term
      }
    },
    resetGrade () {
      this.userCached.cachedSelectedGradeId = null
      this.saveUserCached()
      this.selectedGradeId = null
      if (this.searchTerms.some(term => term.includes('grade'))) {
        this.searchTerms = this.searchTerms.filter(term => !term.includes('grade')) // Clean search term
        this.updateSearchTermsCache()
      }
    },
    resetCompletion () {
      this.userCached.cachedSelectedRangeDate = null
      this.selectedRangeDate = null
      if (this.searchTerms.some(term => term.includes('completion'))) {
        this.searchTerms = this.searchTerms.filter(term => !term.includes('completion')) // Clean search term
        this.userCached.cachedSearchTerms = this.searchTerms
      }
      this.saveUserCached()
      this.page = 1
      this.loadAsyncData()
    },
    async updateLicenseeDivisionIdCache (licenseeDivisionId) {
      this.userCached.cachedSelectedLicenseeDivisionId = licenseeDivisionId
      this.saveUserCached()
      this.page = 1
      await this.loadAsyncData()
    },
    async updateSearchTermsCache () {
      this.userCached.cachedSearchTerms = this.searchTerms
      this.saveUserCached()
      this.page = 1
      await this.loadAsyncData()
    },
    updateInvoiceProject (selectedInvoiceProject) {
      // if (selectedInvoiceProject === '1') {
      //   this.loadAsyncData(['invoice = ready'])
      // } else {
      //   this.loadAsyncData()
      // }
      this.loadAsyncData()
      this.userCached.cachedSelectedInvoiceProject = selectedInvoiceProject
      this.saveUserCached()
    },
    async updateConnectionFilter (selectedConnection) {
      if (selectedConnection) {
        let connection = this.connections.find(c => c.id === selectedConnection)
        if (connection) {
          if (!this.hasSearchTerm('connection', connection.name)) {
            this.searchTerms = this.searchTerms.filter(term => !term.includes('connection')) // Clean search term
            this.userCached.cachedSelectedConnectionId = selectedConnection
            this.resetSize()
            this.saveUserCached()
          }
          // Initialize sizes
          if (selectedConnection) {
            this.sizesFiltered = await AdminApi.getConnectionSizesByConnectionId(selectedConnection)
          }
        }
      }
    },
    updateRangeDateFilter (selectedPeriod) {
      if (selectedPeriod && selectedPeriod.length > 1) {
        if (!this.hasSearchTerm('completion', selectedPeriod)) {
          this.searchTerms = this.searchTerms.filter(term => !term.includes('completion')) // Clean search term
          this.userCached.cachedSelectedRangeDate = selectedPeriod
          this.saveUserCached()
          this.toggleSearchTerm('completion', this.selectedString)
        }
      }
    },
    updateSizeFilter (selectedSize) {
      if (!this.hasSearchTerm('size', selectedSize)) {
        this.searchTerms = this.searchTerms.filter(term => !term.includes('size')) // Clean search term
        this.userCached.cachedSelectedSizeId = selectedSize
        this.saveUserCached()
      }
    },
    updateGradeFilter (gradeId) {
      if (this.gradeById[gradeId] && !this.hasSearchTerm('grade', this.gradeById[gradeId].name)) {
        this.searchTerms = this.searchTerms.filter(term => !term.includes('grade')) // Clean search term
        this.userCached.cachedSelectedGradeId = gradeId
        this.saveUserCached()
      } else if (!gradeId) {
        this.resetGrade()
      }
    },
    removeSearchTerm (term) {
      if (term.includes(' = ')) {
        let chunks = term.split(/ = /, 2)
        let property = chunks[0].trim()
        // let value = chunks[1].trim()

        switch (property) {
          case 'connection':
            this.resetConnection()
            break
          case 'size':
            this.resetSize()
            break
          case 'grade':
            this.resetGrade()
            break
          case 'completion':
            this.resetCompletion()
            break

          default:
            break
        }
      }
    },
    goToProject (project) {
      this.$router.push({
        name: 'royalty_tracker_project',
        params: { id: project.id, type: project.type }
      })
    },
    goNewProject (type) {
      this.$router.push({
        name: 'royalty_tracker_project',
        params: { id: 'new', type: type }
      })
    },
    async generateInvoice (routeRedirect = 'royalty_tracker_invoice') {
      if (this.checkedRows.length > 0) {
        // Create the invoice ID
        var maxInvoiceNumber = await InvoicesApi.getLastInvoiceNumber()
        if (maxInvoiceNumber.number < 2000) {
          this.invoice.number = 2000
        } else {
          this.invoice.number = ++maxInvoiceNumber.number
        }
        // eslint-disable-next-line
        this.invoice.number = this.invoice.number
        this.invoice.codeNumber = this.licenseesById[this.selectedLicenseeId].code + '-' + this.invoice.number
        this.invoice.taxWithholdingPercent = this.licenseesById[this.selectedLicenseeId].taxWithholdingPercent
        this.invoice = await InvoicesApi.createInvoice(this.invoice)

        this.checkedRows.map(async project => {
          if (!project.invoiceSent) {
            project.invoiceId = this.invoice.id
            project.invoice = 'unsent'
            project = await ProjectsApi.updateProject(project.id, project)
            this.projectsFiltered[this.projectsFiltered.indexOf(project)] = project
          }
        })

        if (routeRedirect) {
          let invoiceRoute = this.$router.resolve({
            name: routeRedirect,
            params: {
              invoiceId: this.invoice.id,
              licenseeId: this.selectedLicenseeId,
              licenseeDivisionId: this.selectedLicenseeDivisionId || 'all'
              // periodStartDate: Moment(this.selectedProjects.start).format('YYYY-MM-DD')
            }
          })

          window.open(invoiceRoute.href, '_blank')
        }

        this.selectedProjects = null
        this.invoice = this.initialInvoiceState()
        this.initializeScreen()
      }
    },
    goToInvoice () {
      let routeData = this.$router.resolve({
        name: 'royalty_tracker_invoice',
        params: {
          invoiceId: this.selectedInvoice,
          licenseeId: this.selectedLicenseeId
        }
      })
      window.open(routeData.href, '_blank')
    },
    async createInvoice () {
      this.generateInvoice('royalty_tracker_invoice')
    },
    hasOffice (value) {
      return this.selectedOffices.some(term => term === value)
    },
    saveUserCached () {
      window.localStorage.setItem(this.profile.username, JSON.stringify(this.userCached))
    },
    toggleOffice (value) {
      if (this.hasOffice(value)) {
        // Take off the office cliked
        this.selectedOffices = this.selectedOffices.filter(term => term !== value)
      } else {
        this.selectedOffices.push(value)
      }
      this.userCached.cachedSelectedOffices = this.selectedOffices
      // this.saveUserCached()
      this.updateSearchTermsCache()
    },
    allOffices () {
      if (this.selectedOffices.length === this.offices.length) {
        this.selectedOffices = []
      } else {
        this.offices.map(o => {
          if (!this.hasOffice(o.id)) {
            this.selectedOffices.push(o.id)
          }
        })
      }
      this.userCached.cachedSelectedOffices = this.selectedOffices
      // this.saveUserCached()
      this.updateSearchTermsCache()
    },
    hasSearchTerm (field, value) {
      return this.searchTerms.some(term => term === `${field} = ${value}`)
    },
    toggleSearchTerm (field, value) {
      if (this.hasSearchTerm(field, value)) {
        this.searchTerms = this.searchTerms.filter(term => term !== `${field} = ${value}`)
      } else {
        this.searchTerms.push(`${field} = ${value}`)
      }
      this.updateSearchTermsCache()
    },
    getInvoiceStatus (projects) {
      if (!projects) return []
      return projects.map(project => {
        project.invoice = ProjectsUtil.getProjectInvoiceStatus(project)
      })
    },
    async generateCsv () {
      this.loadingProjects = true

      let params = {
        filter: {
          licenseeId: this.selectedLicenseeId,
          divisions: this.profile.type === 'jfe' ? this.selectedLicenseeDivisionId ? [this.selectedLicenseeDivisionId] : [] : this.profile.divisions,
          searchTerms: this.selectedInvoiceProject === '1' ? this.searchTerms.concat(['invoice = ready']) : this.searchTerms,
          offices: this.selectedOffices,
          endDateRange: this.selectedRangeDate,
          projectTypes: null,
          limit: 999999,
          page: 1,
          orderFields: [this.sortField, this.sortOrder]
        }
      }

      let projects = await ProjectsApi.getProjects(params)
      this.loadingProjects = false

      let data = projects.projects.filter(project => {
        if ((this.searchTerms.length === 0) && ((project.status === 'canceled'))) {
          return true
        } else {
          return true
        }
      }).map(project => {
        var connectionGrade = null

        switch (project.materialType) {
          case 1:
            connectionGrade = project.connectionGradeName
            break
          case 2:
            connectionGrade = project.craDescription
            break
          case 3:
            connectionGrade = 'Other Material'
            break
          default:
            connectionGrade = 'Not Found'
        }

        if (project.type === 'manufacturing') {
          project.totalUnits = (project.joints || 0) + (project.couplings || 0)
        } else {
          project.totalUnits = (project.pins || 0) + (project.boxes || 0)
        }

        return [
          project.id,
          project.status,
          project.internalId,
          project.type,
          project.orderNumber,
          project.licenseeName,
          project.licenseeDivisionName,
          project.name,
          project.endUser,
          project.endDate,
          project.connectionName,
          project.connectionSize,
          project.connectionWeight,
          connectionGrade,
          project.pins,
          project.boxes,
          project.joints,
          project.couplings,
          project.totalUnits,
          project.connectionRate,
          project.royalty
        ]
      })

      let csv = PapaParse.unparse({
        fields: ['ID', 'Status', 'PO#', 'Type', 'Order Number', 'Licensee', 'Division', 'Project', 'End User', 'Completion Date', 'Connection', 'Size', 'Weight', 'Grade', 'Pins', 'Boxes', 'Joints', 'Couplings', 'Units', 'Royalty', 'Total'],
        data: data
      })

      let slug = RandomString.generate(20)

      await ReportsApi.saveReport(slug, 'text/csv;charset=utf-8', 'projects.csv', csv)

      document.location.href = `${process.env.VUE_APP_API_BASE}/api/v1/reports/${slug}/download`
    },
    async create5000Dummies () {
      console.log('started')
      await this.$store.dispatch('refreshConnectionsLight', { hasImages: false, hasSizes: true, hasWeights: true })
      await this.$store.dispatch('refreshConnectionGrades')
      let grades = this.$store.state.connectionGrades
      await this.$store.dispatch('refreshLicenseeConnections')
      this.showDummyButton = false
      this.countDummiesProjects = 1

      for (var i = 0; i < this.numberOfDummies; i++) {
        var projectDummy = {
          type: null,
          name: null,
          endUser: null,
          description: null,
          location: null,
          startDate: null,
          endDate: null,
          contactName: null,
          contactEmail: null,
          contactPhone: null,
          internalId: null,
          licenseeId: null,
          licenseeDivisionId: null,
          connectionId: null,
          connectionSizeId: null,
          connectionGradeId: null,
          materialType: null,
          craDescription: null,
          connectionWeight: null,
          connectionThreading: null,
          manufacturer: null,
          pins: null,
          boxes: null,
          joints: null,
          couplings: null,
          totalEnds: null,
          royalty: null,
          total: null,
          status: null,
          sentNotification: false
        }

        // Project Name
        projectDummy.name = 'Dummy Project_' + i + '_' + RandomString.generate(5)

        // Delete or not
        let projectDeleted = [0, 1]
        projectDummy.deleted = projectDeleted[Math.floor(Math.random() * projectDeleted.length)]

        let projectType = ['manufacturing', 'repair', 'accessory']
        projectDummy.type = projectType[Math.floor(Math.random() * projectType.length)]

        var licenseeConnections = []

        if (projectDummy.type === 'manufacturing') {
          licenseeConnections = this.$store.state.licenseeConnections.filter(l => this.connections.find(c => c.id === l.connectionId && c.royaltyAvailability) && l.manufacture)
        } else if (projectDummy.type === 'repair') {
          licenseeConnections = this.$store.state.licenseeConnections.filter(l => this.connections.find(c => c.id === l.connectionId && c.royaltyAvailability) && l.repair)
        } else if (projectDummy.type === 'accessory') {
          licenseeConnections = this.$store.state.licenseeConnections.filter(l => this.connections.find(c => c.id === l.connectionId && c.royaltyAvailability) && l.accessory)
        }
        let licenseeId = null
        let licensee = null

        while (true) {
          // Licensee
          licenseeId = licenseeConnections[Math.floor(Math.random() * licenseeConnections.length)].licenseeId
          licensee = this.licensees.find(l => l.id === licenseeId)
          if (licensee) {
            break
          }
        }

        projectDummy.licenseeId = licensee.id

        // Connection
        let connectionsFiltered = this.connections.filter(c => {
          let licenseeConnec = licenseeConnections.find(lc => (lc.licenseeId === projectDummy.licenseeId) && (lc.connectionId === c.id))
          if (licenseeConnec) {
            if (projectDummy.type === 'manufacturing') {
              return licenseeConnec.manufacture
            } else if (projectDummy.type === 'repair') {
              return licenseeConnec.repair
            } else if (projectDummy.type === 'accessory') {
              return licenseeConnec.accessory
            } else {
              return false
            }
          } else {
            return false
          }
        }).filter(c => c.royaltyAvailability)

        let connection = connectionsFiltered[Math.floor(Math.random() * connectionsFiltered.length)]
        projectDummy.connectionId = connection.id

        // Licensee Connection Override
        let licenseeConnection = licenseeConnections.find(lc => (lc.licenseeId === licensee.id) && (lc.connectionId === connection.id))

        // Division
        if (licensee.divisions.length > 0) {
          projectDummy.licenseeDivisionId = licensee.divisions[Math.floor(Math.random() * licensee.divisions.length)].id
        } else {
          projectDummy.licenseeDivisionId = null
        }

        // Size
        let sizesFiltered = connection.sizes.filter(s => !s.deleted)
        let size = sizesFiltered[Math.floor(Math.random() * sizesFiltered.length)]

        if (connection.sizes.length > 0) {
          let indexWeights = Math.floor(Math.random() * size.weights.length) // Include all deleted

          if (size) {
            projectDummy.connectionSizeId = size.id
            if (size.weights.length > 0) {
              projectDummy.connectionWeight = size.weights[indexWeights].weight
            } else {
              projectDummy.connectionWeight = null
            }
          } else {
            projectDummy.connectionSizeId = null
            projectDummy.connectionWeight = null
          }
        } else {
          projectDummy.connectionSizeId = null
          projectDummy.connectionWeight = null
        }

        // Type Project, Material Type and Grade
        projectDummy.materialType = this.getRandomIntInclusive(1, 3) // 1-JFE Grade, 2-CRA, 3- OTher Material
        let projectJoints = this.getRandomIntInclusive(1, 10)
        let projectCouplings = this.getRandomIntInclusive(1, 10)
        let projectPins = this.getRandomIntInclusive(1, 10)
        let projectBoxes = this.getRandomIntInclusive(1, 10)
        var totalUnits = 0
        var connectionGrade = null
        let grade = grades[Math.floor(Math.random() * grades.length)]
        let field

        switch (projectDummy.materialType) {
          case 1:
            connectionGrade = grade.name
            projectDummy.connectionGradeId = grade.id

            // Connection Rate
            if (projectDummy.type === 'manufacturing') {
              field = grade && grade.chrome ? 'priceManCra' : 'priceManNon'
            } else if (projectDummy.type === 'repair') {
              field = grade && grade.chrome ? 'priceRepCra' : 'priceRepNon'
            } else if (projectDummy.type === 'accessory') {
              field = grade && grade.chrome ? 'priceAccCra' : 'priceAccNon'
            }
            break
          case 2:
            connectionGrade = RandomString.generate({ length: 12, charset: 'alphabetic' })
            projectDummy.connectionGradeId = null

            // Connection Rate
            if (projectDummy.type === 'manufacturing') {
              field = 'priceManCra'
            } else if (projectDummy.type === 'repair') {
              field = 'priceRepCra'
            } else if (projectDummy.type === 'accessory') {
              field = 'priceAccCra'
            }
            break
          case 3:
            connectionGrade = 'Other Material'
            projectDummy.connectionGradeId = null

            // Connection Rate
            if (projectDummy.type === 'manufacturing') {
              field = 'priceManNon'
            } else if (projectDummy.type === 'repair') {
              field = 'priceRepNon'
            } else if (projectDummy.type === 'accessory') {
              field = 'priceAccNon'
            }
            break
          default:
            connectionGrade = 'Not Found'
            projectDummy.connectionGradeId = null
            field = null
        }

        projectDummy.craDescription = connectionGrade

        // Connection Rate
        // Has override values?
        let licenseeSize = licenseeConnection ? licenseeConnection.sizes.find(s => s.connectionSizeId === projectDummy.connectionSizeId) : null
        if (licenseeSize && size && projectDummy.materialType !== 3) {
          projectDummy.connectionRate = licenseeSize[field] || size[field]
        } else if (size) {
          projectDummy.connectionRate = size[field]
        }

        if (projectType === 'manufacturing') {
          totalUnits = (projectJoints || 0) + (projectCouplings || 0)
        } else {
          totalUnits = (projectPins || 0) + (projectBoxes || 0)
        }

        // START DATE
        var periodMonths = 1

        if (licensee.billingCycle === 'Quarterly') {
          periodMonths = 3
        } else if (licensee.billingCycle === 'Semiannually') {
          periodMonths = 6
        }

        let startDate = Moment(this.randomDate(new Date(2019, 0, 1), new Date()))
        projectDummy.startDate = Moment(startDate).format('YYYY-MM-DD hh:mm:ss')
        projectDummy.endDate = Moment(startDate.clone().add(periodMonths, 'month').subtract(1, 'day')).format('YYYY-MM-DD hh:mm:ss')

        // Connection Threading
        let connectionThreading = ['API Modified', 'Clear-Run', null]
        projectDummy.connectionThreading = connectionThreading[Math.floor(Math.random() * connectionThreading.length)]

        // Order Number
        projectDummy.orderNumber = licensee.code + '-' + this.getRandomIntInclusive(1000, 9999)

        // Royalty
        projectDummy.total = totalUnits * projectDummy.connectionRate
        projectDummy.royalty = totalUnits * projectDummy.connectionRate
        if (!projectDummy.royalty) {
          console.log('projectDummy.royalty=' + projectDummy.royalty)
          console.log('projectDummy.materialType=' + projectDummy.materialType)
          console.log('sizeID=' + size.id)
          console.log('totalUnits=' + totalUnits)
          console.log('connectionRate=' + projectDummy.connectionRate)
          console.log('field=' + field)
        }
        projectDummy.joints = projectJoints
        projectDummy.couplings = projectCouplings
        projectDummy.pins = projectPins
        projectDummy.boxes = projectBoxes
        projectDummy.totalEnds = null

        // Status
        if (projectDummy.type === 'accessory') {
          projectDummy.status = 'accepted'
        } else {
          let statusProject = ['accepted', 'rejected', 'canceled', null, null, null]
          projectDummy.status = statusProject[Math.floor(Math.random() * statusProject.length)]
        }

        console.log('i=' + i +
          '/' + projectDummy.name +
          '/' + connection.name +
          '/' + licensee.id +
          '/' + projectDummy.licenseeDivisionId +
          '/' + projectDummy.connectionSizeId +
          '/' + projectDummy.connectionWeight +
          '/' + projectDummy.materialType +
          '/' + projectDummy.type +
          '/' + connectionGrade +
          '/' + totalUnits +
          '/' + projectDummy.startDate +
          '/' + projectDummy.endDate +
          '/' + projectDummy.connectionThreading +
          '/' + projectDummy.orderNumber +
          '/' + projectDummy.connectionGradeId +
          '/' + projectDummy.craDescription +
          '/' + projectDummy.connectionRate +
          '/' + projectDummy.total +
          '/' + projectDummy.royalty +
          '/' + projectDummy.status +
          '/' + projectDummy.deleted
        )
        await ProjectsApi.createProject(projectDummy)
        this.countDummiesProjects++
      }
      location.reload()
    },
    getRandomIntInclusive (min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min // The maximum is inclusive and the minimum is inclusive
    },
    randomDate (start, end) {
      return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
    }
  }
}

</script>
