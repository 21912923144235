export default {
  getProjectInvoiceStatus (project) {
    let invoiceStatus = ''

    if (project.invoiceCreateDate) {
      if (project.invoiceDue) {
        invoiceStatus = 'due'
      } else if (project.invoicePaid) {
        invoiceStatus = 'paid'
      } else if (project.invoiceSent) {
        invoiceStatus = 'sent'
      } else {
        invoiceStatus = 'unsent'
      }
    } else {
      try {
        var endDateProjectSplitted = project.endDate.split('-')
        var projectEndDate = new Date(endDateProjectSplitted[0], endDateProjectSplitted[1] - 1, endDateProjectSplitted[2])
        var d = new Date()
        var startPeriodDate
        var endPeriodDate

        switch (project.licenseeBillingCycle) {
          case 'Monthly':
            // console.log("============= Monthly " + project.name)
            startPeriodDate = new Date(d.getFullYear(), d.getMonth(), 1)
            endPeriodDate = new Date(projectEndDate.getFullYear(), projectEndDate.getMonth() + 1, 0)
            break
          case 'Quarterly':
            // console.log("============= Quarterly " + project.name)
            var currentQuarter = this.getQuarter('Current', projectEndDate)
            startPeriodDate = currentQuarter.startDate
            endPeriodDate = currentQuarter.endDate
            break
          case 'Semiannually':
            // console.log("============= Semiannually " + project.name)
            if (projectEndDate.getMonth() < 6) {
              startPeriodDate = new Date(d.getFullYear(), 0, 1)
              endPeriodDate = new Date(projectEndDate.getFullYear(), 6, 0) // This means Last Day of June
            } else {
              startPeriodDate = new Date(d.getFullYear(), 6, 1)
              endPeriodDate = new Date(projectEndDate.getFullYear(), 12, 0) // This means Last Day of December
            }
            break
          default:
            startPeriodDate = new Date()
            endPeriodDate = new Date()
        }

        if (d > endPeriodDate) {
          if (project.status === 'accepted') {
            invoiceStatus = 'ready'
          } else {
            invoiceStatus = 'unapproved' // Projects that lose the date to approve the project. Invoices get the unapproved status
          }
        } else {
          if ((d > startPeriodDate) && (project.status !== 'canceled')) {
            invoiceStatus = 'in cycle'
          } else {
            invoiceStatus = 'pending'
          }
        }
      } catch (error) {
        console.log(error)
        console.log('project id=' + project.id)
        invoiceStatus = 'XX'
      }
    }

    return invoiceStatus
  },
  getQuarter (id, d) {
    var quarter = Math.floor((d.getMonth() / 3))
    var firstDate = null
    var endDate = null

    switch (id) {
      case 'Current':
        firstDate = new Date(d.getFullYear(), quarter * 3, 1)
        endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0)
        break
      case 'Text':
        let q = { 1: 'Q1', 2: 'Q1', 3: 'Q1', 4: 'Q2', 5: 'Q2', 6: 'Q2', 7: 'Q3', 8: 'Q3', 9: 'Q3', 10: 'Q4', 11: 'Q4', 12: 'Q4' }
        return q[d.getMonth()]
      case 'Previous':
        firstDate = new Date(d.getFullYear(), quarter * 3 - 3, 1)
        endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0)
        break
    }

    return { firstDate, endDate }
  }
}
